<template>
  <VContainer
    fluid
    class="report-progress"
  >
    <VRow class="align-center">
      <VCol cols="9">
        <h1 class="mb-2 tt-text-headline-1">
          Отчет по прогрессу
        </h1>
        <div class="tt-black--text text--lighten-2 mb-4">
          Показывает, сколько заданий на каждом из уровней выполнили сотрудники
        </div>
      </VCol>
    </VRow>
    <VRow class="mb-4 align-center">
      <VCol
        cols="4"
        class="py-0"
      >
        <TTSelect
          ref="locationDropDown"
          :value="currentLocationId"
          :items="locations"
          item-text="name"
          item-value="id"
          return-object
          large
          label="Локация"
          :menu-props="{ auto : true }"
          class="tt-select-attach"
          attach
          placeholder="Выбор локации"
          @change="handleLocation"
        >
          <template #selection="{item,disabled}">
            <TextTemplate
              class="v-select__selection v-select__selection--comma"
              :disabled="disabled"
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
          <template #item="{item}">
            <TextTemplate
              :text="item.name"
              :show-prefix="!item.is_active && !!item.id"
            />
          </template>
        </TTSelect>
      </VCol>
      <VCol
        cols="4"
        class="py-0"
      >
        <TTSelect
          v-model="filter.state"
          :items="stateType"
          item-text="name"
          large
          label="Статус"
          placeholder="Выбор статуса"
          data-test="choose-status"
          @change="handleStatus"
        />
      </VCol>
      <VCol
        cols="4"
        class="py-0"
      >
        <TTTextField
          v-model="filter.name"
          large
          placeholder="Поиск по ФИО"
          append-icon="fal fa-search"
          label="Поиск"
          data-test="input-search"
          @input="handleProgressTextSearch"
        />
      </VCol>
    </VRow>
    <VRow v-if="currentLocationId">
      <VCol v-if="dataLoaded && !entityList">
        <h2 class="mb-3">
          Нет данных
        </h2>
        <p class="mb-0">
          Нет данных для отображения по заданным параметрам.
        </p>
      </VCol>
      <VCol v-else>
        <TTDataTable
          v-if="levelsLoaded"
          :items="entityList"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :loading="!dataLoaded"
          hide-default-header
        >
          <template #header="{ props : { headers : heads } }">
            <thead class="v-data-table-header">
              <tr>
                <template v-for="(header, i) in heads">
                  <th
                    v-if="i < 2"
                    :key="header.value"
                    scope="col"
                    role="columnheader"
                    class="text-left"
                    :style="header.width? `width: ${header.width};`: ''"
                  >
                    <span>{{ header.text }}</span>
                  </th>
                  <th
                    v-else
                    :key="header.value"
                    scope="col"
                    role="columnheader"
                    class="text-start"
                    :style="{width : `${60 / (heads.length - 2)}%`}"
                  >
                    <div class="report-progress__header">
                      <VIcon
                        v-if="i===2 && startRow > 0"
                        small
                        color="#0B1218"
                        class="report-progress__header-icon report-progress__header-icon_left"
                        @click="startRow -= 1"
                      >
                        fal fa-chevron-left
                      </VIcon>
                      <TTTooltip
                        bottom
                        content-class="report-progress__tooltip"
                        max-width="360"
                      >
                        <template #activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            :class="{'ml-4' : i===2 && startRow > 0}"
                            v-on="on"
                          >
                            {{ header.text }}
                          </span>
                        </template>
                        <div class="pa-4">
                          <h4 class="tt-text-body-2 mb-1">
                            {{ header.name }}
                          </h4>
                          <div class="tt-text-body-2 tt-light-mono-46--text">
                            {{ header.fullText }}
                          </div>
                        </div>
                      </TTTooltip>
                      <VIcon
                        v-if="( i === headers.length - 1 ) && (startRow + TOTAL_LEVELS_SHOW < levelHeaders.length)"
                        small
                        color="#0B1218"
                        class="report-progress__header-icon report-progress__header-icon_right"
                        @click="startRow += 1"
                      >
                        fal fa-chevron-right
                      </VIcon>
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template #item="{item, headers : heads}">
            <tr>
              <td
                class="cursor--pointer tt-text-body-2"
                @click="$router.push(`/staff/${item.staff.id}`)"
              >
                <div class="my-4">
                  {{ item.staff.last_name }} {{ item.staff.first_name }}
                  <TTTooltip
                    bottom
                    max-width="300"
                  >
                    <template #activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        class="tt-text-body-2 tt-light-mono-46--text ellipsis-two-lines ellipsis mb-0"
                        v-on="on"
                      >
                        {{ currentLocationName }}
                        <span
                          v-if="item.staff.position"
                        >, {{ item.staff.position }}</span>
                      </p>
                    </template>
                    <p class="mb-0">
                      {{ currentLocationName }}<span v-if="item.staff.position">, {{ item.staff.position }}</span>
                    </p>
                  </TTTooltip>
                </div>
              </td>
              <td>
                <div class="d-flex align-center">
                  <VProgressCircular
                    rotate="270"
                    :size="40"
                    :value="item.staff.progress"
                    color="primary"
                    width="2"
                    data-test="item-level-count"
                  >
                    <span class="text--black">{{ item.staff.progress }}%</span>
                  </VProgressCircular>
                  <div class="ml-3">
                    {{ item.staff.bonus }}<br>балл
                  </div>
                </div>
              </td>
              <template v-for="(header, i) in heads">
                <td
                  v-if="i > 1"
                  :key="header.value"
                >
                  <ReportProgressCell
                    :level="header.level"
                    :item="item"
                    :staff-detail="cachedStaffDetail[item.staff.id]"
                    @getDetail="getStaffDetail(item.staff.id)"
                  />
                </td>
              </template>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h2 class="mb-3">
          Локация не выбрана
        </h2>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
    <VRow v-if="dataLoaded && !fullLoad">
      <VCol>
        <div v-intersect.quiet="getMore" />
      </VCol>
    </VRow>
    <VRow v-if="!dataLoaded && !fullLoad">
      <VCol align="center">
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { pageLocationToken } from '@/services';
import * as snamiApi from '@/services/backend/snami';
import { staffStates } from '@/constants';
import { truncateString } from '@/utils';
import ReportProgressCell from '@/components/reportProgress/ReportProgressCell.vue';
import { API_REPORT_PROGRESS } from '@/services/backend/snami/constants';
import { downloadCSVLink } from '@/utils/CSV';

import localStorageInterface from '@/services/localStorage/_utils';
import TextTemplate from '@/components/shared/TextTemplate.vue';

const pageReportProgressStatusToken = localStorageInterface('snami_page_report_progress_status');
const pageReportProgressNameToken = localStorageInterface('snami_page_report_progress_name');

const TOTAL_LEVELS_SHOW = 6;

export default {
  name: 'ReportProgress',
  components: { TextTemplate, ReportProgressCell },
  data() {
    return {
      filter: {
        state: pageReportProgressStatusToken.get() || staffStates.ADAPTING,
        name: pageReportProgressNameToken.get() || '',
      },
      page: {},
      total: 0,
      stateType: [
        {
          value: staffStates.ACTIVE,
          name: 'Действующие',
        },
        {
          value: staffStates.INVITED,
          name: 'Приглашенные',
        },
        {
          value: staffStates.ADAPTING,
          name: 'Адаптирующиеся',
        },
        {
          value: staffStates.ADAPTED,
          name: 'Адаптированные',
        },
        {
          value: staffStates.WITHOUT_ADAPTATION,
          name: 'Без адаптации',
        },
        {
          value: staffStates.FIRED,
          name: 'Уволенные',
        },
      ],
      dataLoaded: false,
      levelsLoaded: false,
      startRow: 0,
      TOTAL_LEVELS_SHOW,
      entityList: [],
      locations: [],
      levelList: [],
      cachedStaffDetail: {},
      currentLocationId: null,
    };
  },
  computed: {
    currentPage() {
      return this.page.n || 0;
    },
    totalStaff() {
      return this.page.total_item || 0;
    },
    fullLoad() {
      if (this.entityList) {
        return this.entityList.length >= this.totalStaff;
      }
      return false;
    },
    levelHeaders() {
      return this.levelList.map((item) => ({
        sortable: false,
        align: 'left',
        value: item.n,
        text: `${item.n} ур`,
        fullText: `${item.n} уровень`,
        name: item.name,
        level: item,
      }));
    },
    headers() {
      const headers = [
        {
          sortable: false,
          text: 'Сотрудник',
          align: 'left',
          value: 'staff',
        },
        {
          sortable: false,
          text: 'Прогресс',
          align: 'left',
          value: 'progress',
          width: '120px',
        },
      ];
      if (this.levelHeaders.length <= TOTAL_LEVELS_SHOW) {
        return [...headers, ...this.levelHeaders];
      }
      const levelHeaders = [...this.levelHeaders];
      return [...headers, ...levelHeaders.splice(this.startRow, TOTAL_LEVELS_SHOW)];
    },
    currentLocationName() {
      if (this.currentLocationId && this.locations.length) {
        const locationIdx = this.locations.findIndex((item) => item.id === this.currentLocationId);
        if (locationIdx !== -1) {
          return this.locations[locationIdx].name;
        }
      }
      return '';
    },
  },
  created() {
    this.debouncedGetData = debounce(this.handleFilter, 500);
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocationId = loc;
      this.getLevels();
      this.getData();
    }
    this.$repositories.location.list({}).then((r) => {
      const { data } = r.data;
      this.locations = data;
    }).catch((e) => console.warn(e));
  },
  mounted() {
    if (!this.currentLocationId) {
      this.$refs.locationDropDown.$children[0].isMenuActive = true;
    }
    this.$root.$on('downloadReportProgress', this.downloadCSV);
  },
  beforeDestroy() {
    this.$root.$off('downloadReportProgress', this.downloadCSV);
  },
  methods: {
    truncateString,
    downloadCSV() {
      const link = API_REPORT_PROGRESS(this.currentLocationId);
      downloadCSVLink(link);
    },
    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocationId = item.id;
      this.getLevels();
      this.handleFilter();
    },
    handleStatus() {
      pageReportProgressStatusToken.set(this.filter.state);
      this.handleFilter();
    },
    handleProgressTextSearch() {
      pageReportProgressNameToken.set(this.filter.name);
      this.debouncedGetData();
    },
    handleFilter() {
      this.page = {};
      this.getData();
    },
    async getLevels() {
      if (!this.currentLocationId) return;
      this.levelsLoaded = false;
      this.levelList = [];
      this.startRow = 0;
      try {
        const { data: { data: draftLevelList } } = await snamiApi.getLevel({
          filter: {
            location_id: this.currentLocationId,
          },
        });
        const levelList = draftLevelList.filter((item) => !item.for_tutor);
        const levelIdList = levelList.map((item) => item.id);
        const { data: { data: taskList } } = await this.$repositories.task
          .list({ data: { filter: { level_id: levelIdList } } });
        const levelMap = {};

        levelList.forEach((item) => {
          levelMap[item.id] = { ...item, taskMap: {}, taskIds: [] };
        });
        taskList.forEach((item) => {
          levelMap[item.level_id].taskMap[item.id] = item;
          levelMap[item.level_id].taskIds.push(item.id);
        });

        Object.keys(levelMap).forEach((key) => {
          this.levelList.push(levelMap[key]);
        });
        this.levelList = this.levelList.sort((a, b) => a.n - b.n);
      } catch (e) {
        console.warn(e);
      }
      this.levelsLoaded = true;
    },
    async getData() {
      if (!this.currentLocationId) return;
      this.dataLoaded = false;
      this.entityList = [];
      this.cachedStaffDetail = {};
      try {
        const { filter } = this;
        const { data: { data: reportList, page } } = await this.$repositories.report.getReportProgress({
          data: {
            location_id: this.currentLocationId,
            ...filter,
            page: this.currentPage,
          },
        });
        this.entityList = reportList;
        this.page = page;
      } catch (e) {
        console.warn(e);
      }
      this.dataLoaded = true;
    },
    async getMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.dataLoaded = false;
        try {
          const { filter } = this;
          const { data: { data: reportList, page } } = await this.$repositories.report.getReportProgress({
            data: {
              location_id: this.currentLocationId,
              ...filter,
              page: this.currentPage + 1,
            },
          });
          this.entityList = [...this.entityList, ...reportList];
          this.page = page;
        } catch (e) {
          console.warn(e);
        }
        this.dataLoaded = true;
      }
    },
    async getStaffDetail(staffId) {
      if (this.cachedStaffDetail[staffId]) return;
      try {
        const { data: { data: staffDetail } } = await this.$repositories.staff.get(staffId);
        const taskMap = {};
        staffDetail.tasks.forEach((item) => {
          taskMap[item.id] = item;
        });
        // чтобы не потерять реактивность!
        Vue.set(this.cachedStaffDetail, staffId, taskMap);
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style>
.report-progress .report-progress__header {
  position: relative;
}
.report-progress .report-progress__header-icon {
  position: absolute;
  top: 3px;
}
.report-progress .report-progress__header-icon.report-progress__header-icon_left {
  left: 0;
}
.report-progress .report-progress__header-icon.report-progress__header-icon_right {
  right: 0;
}

.v-tooltip__content.report-progress__tooltip {
  background: #FFFFFF;
  box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08);
  border-radius: 8px;
  padding: 0;
  color: black;
}

</style>
