var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"report-progress",attrs:{"fluid":""}},[_c('VRow',{staticClass:"align-center"},[_c('VCol',{attrs:{"cols":"9"}},[_c('h1',{staticClass:"mb-2 tt-text-headline-1"},[_vm._v(" Отчет по прогрессу ")]),_c('div',{staticClass:"tt-black--text text--lighten-2 mb-4"},[_vm._v(" Показывает, сколько заданий на каждом из уровней выполнили сотрудники ")])])],1),_c('VRow',{staticClass:"mb-4 align-center"},[_c('VCol',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('TTSelect',{ref:"locationDropDown",staticClass:"tt-select-attach",attrs:{"value":_vm.currentLocationId,"items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","large":"","label":"Локация","menu-props":{ auto : true },"attach":"","placeholder":"Выбор локации"},on:{"change":_vm.handleLocation},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('TextTemplate',{staticClass:"v-select__selection v-select__selection--comma",attrs:{"disabled":disabled,"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextTemplate',{attrs:{"text":item.name,"show-prefix":!item.is_active && !!item.id}})]}}])})],1),_c('VCol',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('TTSelect',{attrs:{"items":_vm.stateType,"item-text":"name","large":"","label":"Статус","placeholder":"Выбор статуса","data-test":"choose-status"},on:{"change":_vm.handleStatus},model:{value:(_vm.filter.state),callback:function ($$v) {_vm.$set(_vm.filter, "state", $$v)},expression:"filter.state"}})],1),_c('VCol',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('TTTextField',{attrs:{"large":"","placeholder":"Поиск по ФИО","append-icon":"fal fa-search","label":"Поиск","data-test":"input-search"},on:{"input":_vm.handleProgressTextSearch},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1),(_vm.currentLocationId)?_c('VRow',[(_vm.dataLoaded && !_vm.entityList)?_c('VCol',[_c('h2',{staticClass:"mb-3"},[_vm._v(" Нет данных ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Нет данных для отображения по заданным параметрам. ")])]):_c('VCol',[(_vm.levelsLoaded)?_c('TTDataTable',{attrs:{"items":_vm.entityList,"headers":_vm.headers,"items-per-page":-1,"hide-default-footer":"","loading":!_vm.dataLoaded,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var heads = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_vm._l((heads),function(header,i){return [(i < 2)?_c('th',{key:header.value,staticClass:"text-left",style:(header.width? ("width: " + (header.width) + ";"): ''),attrs:{"scope":"col","role":"columnheader"}},[_c('span',[_vm._v(_vm._s(header.text))])]):_c('th',{key:header.value,staticClass:"text-start",style:({width : ((60 / (heads.length - 2)) + "%")}),attrs:{"scope":"col","role":"columnheader"}},[_c('div',{staticClass:"report-progress__header"},[(i===2 && _vm.startRow > 0)?_c('VIcon',{staticClass:"report-progress__header-icon report-progress__header-icon_left",attrs:{"small":"","color":"#0B1218"},on:{"click":function($event){_vm.startRow -= 1}}},[_vm._v(" fal fa-chevron-left ")]):_vm._e(),_c('TTTooltip',{attrs:{"bottom":"","content-class":"report-progress__tooltip","max-width":"360"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{'ml-4' : i===2 && _vm.startRow > 0}},'span',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('div',{staticClass:"pa-4"},[_c('h4',{staticClass:"tt-text-body-2 mb-1"},[_vm._v(" "+_vm._s(header.name)+" ")]),_c('div',{staticClass:"tt-text-body-2 tt-light-mono-46--text"},[_vm._v(" "+_vm._s(header.fullText)+" ")])])]),(( i === _vm.headers.length - 1 ) && (_vm.startRow + _vm.TOTAL_LEVELS_SHOW < _vm.levelHeaders.length))?_c('VIcon',{staticClass:"report-progress__header-icon report-progress__header-icon_right",attrs:{"small":"","color":"#0B1218"},on:{"click":function($event){_vm.startRow += 1}}},[_vm._v(" fal fa-chevron-right ")]):_vm._e()],1)])]})],2)])]}},{key:"item",fn:function(ref){
var item = ref.item;
var heads = ref.headers;
return [_c('tr',[_c('td',{staticClass:"cursor--pointer tt-text-body-2",on:{"click":function($event){return _vm.$router.push(("/staff/" + (item.staff.id)))}}},[_c('div',{staticClass:"my-4"},[_vm._v(" "+_vm._s(item.staff.last_name)+" "+_vm._s(item.staff.first_name)+" "),_c('TTTooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"tt-text-body-2 tt-light-mono-46--text ellipsis-two-lines ellipsis mb-0"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentLocationName)+" "),(item.staff.position)?_c('span',[_vm._v(", "+_vm._s(item.staff.position))]):_vm._e()])]}}],null,true)},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.currentLocationName)),(item.staff.position)?_c('span',[_vm._v(", "+_vm._s(item.staff.position))]):_vm._e()])])],1)]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('VProgressCircular',{attrs:{"rotate":"270","size":40,"value":item.staff.progress,"color":"primary","width":"2","data-test":"item-level-count"}},[_c('span',{staticClass:"text--black"},[_vm._v(_vm._s(item.staff.progress)+"%")])]),_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.staff.bonus)),_c('br'),_vm._v("балл ")])],1)]),_vm._l((heads),function(header,i){return [(i > 1)?_c('td',{key:header.value},[_c('ReportProgressCell',{attrs:{"level":header.level,"item":item,"staff-detail":_vm.cachedStaffDetail[item.staff.id]},on:{"getDetail":function($event){return _vm.getStaffDetail(item.staff.id)}}})],1):_vm._e()]})],2)]}}],null,false,2959833557)}):_vm._e()],1)],1):_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(" Локация не выбрана ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Выберите локацию для получения данных. ")])])],1),(_vm.dataLoaded && !_vm.fullLoad)?_c('VRow',[_c('VCol',[_c('div',{directives:[{name:"intersect",rawName:"v-intersect.quiet",value:(_vm.getMore),expression:"getMore",modifiers:{"quiet":true}}]})])],1):_vm._e(),(!_vm.dataLoaded && !_vm.fullLoad)?_c('VRow',[_c('VCol',{attrs:{"align":"center"}},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }