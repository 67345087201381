<template>
  <VMenu
    v-model="menu"
    open-on-hover
    top
    offset-y
    class="report-progress-cell"
    content-class="v-menu-shadow"
    max-width="200"
    min-width="200"
  >
    <template #activator="{ on, attrs }">
      <div
        class="d-flex flex-column align-start cursor--pointer"
        v-bind="attrs"
        v-on="on"
      >
        <div class="mb-2">
          {{ completedLevelTasks.length }} / {{ levelTasks.length }}
        </div>
        <VProgressLinear
          :value="progress"
          background-color="#EBECED"
        />
      </div>
    </template>
    <div class="report-progress-cell__card pa-4 v-menu-card">
      <h4 class="tt-text-body-2">
        {{ levelName }}
      </h4>
      <p class="tt-text-body-2 tt-light-mono-46--text mb-2">
        {{ levelNumber }} уровень
      </p>
      <h4 class="tt-text-subtitle">
        {{ completedLevelTasks.length }} из {{ levelTasks.length }} заданий
      </h4>
      <div class="d-flex flex-wrap mt-1 mb-2 report-progress-cell__dot-wrapper">
        <div
          v-for="taskId in levelTasks"
          :key="taskId"
          class="report-progress-cell__dot mt-1 mr-1"
          :class="{'report-progress-cell__dot_active' : completedLevelTasks.indexOf(taskId) !== -1}"
        />
      </div>
      <template v-if="completedLevelTasks.length">
        <template v-if="staffDetail">
          <h4
            class="tt-text-body-2"
            style="white-space: nowrap;"
          >
            {{ staffBonus }} из {{ levelBonus }} баллов
          </h4>
        </template>
        <div
          v-else
          class="d-flex justify-center mt-4"
        >
          <VProgressCircular
            indeterminate
            color="primary"
            width="2"
          />
        </div>
      </template>
    </div>
  </VMenu>
</template>

<script>

export default {
  name: 'ReportProgressCell',
  props: {
    level: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    staffDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    levelName() {
      return this.level.name;
    },
    levelNumber() {
      return this.level.n;
    },
    levelTasks() {
      return this.level.taskIds || [];
    },
    itemTasks() {
      return this.item.task_id || [];
    },
    completedLevelTasks() {
      return this.itemTasks.filter((id) => this.levelTasks.indexOf(id) !== -1);
    },
    progress() {
      if (!this.levelTasks.length) return 0;
      return Math.round((100 * this.completedLevelTasks.length) / this.levelTasks.length);
    },
    levelBonus() {
      return this.level.bonus;
    },
    staffBonus() {
      if (!this.completedLevelTasks.length || !this.staffDetail) return 0;
      return this.completedLevelTasks.reduce((sum, taskId) => {
        const task = this.staffDetail[taskId] || { bonus: 0 };
        return sum + task.bonus;
      }, 0);
    },
    // Пока тут приходит некорректное время
    staffTime() {
      if (!this.completedLevelTasks.length || !this.staffDetail) return 0;
      return this.completedLevelTasks.reduce((sum, taskId) => {
        const task = this.staffDetail[taskId] || { duration_min: 0 };
        return sum + task.duration_min;
      }, 0).toFixed(1);
    },
  },
  watch: {
    menu(val) {
      if (val && this.completedLevelTasks.length) {
        this.$emit('getDetail');
      }
    },
  },
};
</script>

<style>
  .report-progress-cell__card {
    background-color: white;
    box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08);
    border-radius: 8px;
  }

  .report-progress-cell__dot-wrapper {
    max-width: 120px;
  }
  .report-progress-cell__dot {
    border: 1px solid #00A0F2;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  .report-progress-cell__dot.report-progress-cell__dot_active {
    background-color: #00A0F2;
  }
</style>
